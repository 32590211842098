import React from "react";
import { Container } from "react-bootstrap";

const Ticketpanel = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 pt-18 position-relative">
        <div className="hero-area bg-image mx-6 mx-lg-9 rounded-15">
          <Container>
            <div id="sb-tickets"></div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Ticketpanel;

import { graphql } from "gatsby";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";
import Ticketpanel from "../sections/landing7/Ticketpanel";

const Support = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "left",
          isFluid: true,
          button: "account", // cta, account, null
        }}
        footerConfig={{
          style: "style2",
          theme: "light",
        }}
      >
        <Header />
        <Ticketpanel />
        <Footer />
      </PageWrapper>
    </>
  );
};
export default Support;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
